import { hasAccess, MultipleSelect } from '@opinary/ui-components'

import { getPermissions } from '../../api/auth.ts'
import { Customer } from '../../api/rest/types.ts'
import { PERMISSIONS } from '../../constants.ts'
import { SelectContainer } from './CustomerPicker.style.ts'
import { TRANSLATIONS } from './translations.ts'

type Props = {
  availableCustomers: Customer[]
  setCustomers: (customers: Customer[]) => void
  selectedCustomers: Customer[]
}

const CustomerPicker = ({
  availableCustomers,
  selectedCustomers,
  setCustomers,
}: Props) => {
  const permissions = getPermissions()

  const isAdmin = hasAccess(permissions, [PERMISSIONS.ADMIN])

  const optionsMap = (customer: Customer) => ({
    label: customer.name,
    value: customer.id,
  })
  const options = availableCustomers.map(optionsMap)
  const selectedOptions = selectedCustomers.map(optionsMap)

  const onChange = (values: unknown) => {
    const newlySelectedCustomers = (
      values as {
        value: string
        label: string
      }[]
    ).map(option => {
      return availableCustomers.find(customer => customer.id === option.value)!
    })

    setCustomers(newlySelectedCustomers)
  }

  if (!options?.length || options.length === 1) return null

  return (
    <SelectContainer>
      <MultipleSelect
        value={selectedOptions}
        selectAllEnabled
        options={options}
        isSearchable
        disableOnSelectAll={isAdmin}
        selectAllLabel={TRANSLATIONS.SELECT_ALL}
        onChange={onChange}
        placeholder={
          isAdmin
            ? TRANSLATIONS.ADMIN_PLACEHOLDER
            : TRANSLATIONS.CUSTOMER_PLACEHOLDER
        }
      />
    </SelectContainer>
  )
}

export default CustomerPicker
